import React, { useState } from "react";
import Layout from "../components/layout";
import Typewriter from "../components/typewriter";
import Project from "../components/project";
import projectList from "../data/projects.json";
import ImageView from "../components/imageView";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

export default ({ data }) => {
    const [touched, setTouched] = useState(false);
    const [viewedImage, setViewedImage] = useState(null);
    const { edges: projectImages } = data.ProjectImages;
    const { edges: mobileProjectImages } = data.MobileProjectImages;
    const { edges: projectStaticImages } = data.StaticProjectImages;
    const { publicURL: Qh1 } = data.Qh1;
    const { publicURL: Qh2 } = data.Qh2;
    const { publicURL: Qh3 } = data.Qh3;
    const { publicURL: Qh4 } = data.Qh4;

    return (
        <>
            <Helmet>
                <html lang="en"/>
                <meta charSet="utf-8"/>
                <title>Projects - Jordan Miller</title>
                <meta name="description" content="I'm Jordan Miller, a full stack web developer. Here are some of the projects I've worked on."/>
                <link rel="canonical" href="https://jordanmiller.dev/projects"/>
                <meta property="og:image:secure_url" content="https://jordanmiller.dev/ogpImage.jpg"/>
                <meta property="og:image" content="http://jordanmiller.dev/ogpImage.jpg" />
                <meta property="og:title" content="Projects - Jordan Miller"/>
                <meta property="og:description" content="I'm Jordan Miller, a full stack web developer. Here are some of the projects I've worked on."/>
                <meta property="og:url" content="https://jordanmiller.dev/projects"/>
            </Helmet>
            <Layout>
                {viewedImage && ( <ImageView setViewedImage={setViewedImage} image={viewedImage} /> )}
                <Typewriter text="Things I've worked on..." />
                {projectList && projectList.map((project, i) => {
                        const images = projectImages.concat(mobileProjectImages)
                            .filter((image, i) => { return project.images.includes(image.node.relativePath); });
                        return (
                            <Project key={project.name} project={project}
                                touched={touched} setTouched={setTouched} setViewedImage={setViewedImage}
                                sizes={images.map((image) => image.node.childImageSharp.fluid)}
                                staticImages={projectStaticImages
                                    .filter((node) => node.node.name.match(new RegExp(`${project.imageQuery}`, "g")))
                                    .sort((a, b) => a.node.name > b.node.name ? 1 : -1)}
                                Qh1={Qh1} Qh2={Qh2} Qh3={Qh3} Qh4={Qh4} 
                            />
                        );
                    })}
                <br />
            </Layout>
        </>
    );
};

export const query = graphql`
    query projectImages {
        ProjectImages: allFile(
            sort: { order: ASC, fields: [absolutePath] }
            filter: { relativePath: { regex: "/^((?!.*mobile.*).)*.png$/" } }
        ) {
            edges {
                node {
                    relativePath
                    name
                    childImageSharp {
                        fluid(
                            maxWidth: 800
                            srcSetBreakpoints: [
                                200
                                300
                                400
                                500
                                600
                                700
                                800
                            ]
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        MobileProjectImages: allFile(
            sort: { order: ASC, fields: [absolutePath] }
            filter: { relativePath: { regex: "/^(mobile.*).png$/" } }
        ) {
            edges {
                node {
                    relativePath
                    name
                    childImageSharp {
                        fluid(
                            maxWidth: 400
                            srcSetBreakpoints: [100, 200, 300, 400]
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        StaticProjectImages: allFile(filter: { extension: { eq: "png" } }) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        Qh1: file(relativePath: { regex: "/quackhire1.gif/" }) { publicURL }
        Qh2: file(relativePath: { regex: "/quackhire2.gif/" }) { publicURL }
        Qh3: file(relativePath: { regex: "/quackhire3.gif/" }) { publicURL }
        Qh4: file(relativePath: { regex: "/quackhire4.gif/" }) { publicURL }
    }
`;
