import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import Carousel from "./carousel";
import {CarouselWrapper, H4, LinkDiv, LinkSpan, P, ProjectContainer, StyledGithub, StyledImg, StyledLi, StyledLinkIcon, StyledSpan} from "../styles/project";
import styled from "styled-components";
import "react-gif-player/dist/gifplayer.css";

export default ({sizes, project, touched, setTouched, setViewedImage, staticImages, Qh1, Qh2, Qh3, Qh4}) => {
    const ref = useRef();
    const [dimensions, setDimensions] = useState({});
    const [imageLoading, setImageLoading] = useState(true);
    const [currentGif, setCurrentGif] = useState(0);
    const [gifArray] = useState([
        { source: Qh1, alt: 'Track Status feature gif example', text: 'Track jobs with a single click'},
        { source: Qh2, alt: 'Goal Graph feature gif example', text: 'Set and track daily goals'},
        { source: Qh3, alt: 'Change Styling feature gif example', text: 'Modify styling by status'},
        { source: Qh4, alt: 'Quackhire dashboard gif example', text: 'Search for and manage jobs on Quackhire.com'},
    ]);
    
    useEffect(() => {
        if(!imageLoading){
            if(currentGif === 0){ setTimeout(function(){ setCurrentGif(1) }, 11500); }
            if(currentGif === 1){ setTimeout(function(){ setCurrentGif(2) }, 13516); }
            if(currentGif === 2){ setTimeout(function(){ setCurrentGif(3) }, 13576); }
            if(currentGif === 3){ setTimeout(function(){ setCurrentGif(0) }, 13509); }
        }
    }, [currentGif, imageLoading])

    useLayoutEffect(() => {
        setDimensions(ref.current && ref.current.getBoundingClientRect());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current]);

    return (
        <ProjectContainer>
            <h2>{project.name}</h2>
            {project.images.length > 0 &&
                project.images[0].includes('.gif') 
                ? <CarouselWrapper style={{boxShadow: 'none'}}>
                    <StyledGifWrapper ref={ref}>
                        <img src={gifArray[currentGif].source} alt={gifArray[currentGif].alt} onLoad={()=>{setImageLoading(false)}}/>
                    </StyledGifWrapper>
                </CarouselWrapper>
                : project.images.length > 0 ? <CarouselWrapper>
                    <div style={{ background: "rgba(76, 175, 80, 0)" }} ref={ref}>
                        <Carousel top={dimensions.height / 2 - 22} left={55} right={55} touched={touched} setTouched={setTouched}>
                            {sizes.map((size, i) => {
                                return (
                                    <div role="button" tabIndex={0} key={i}
                                        onClick={() => {
                                            setViewedImage(staticImages[i]?.node?.childImageSharp.fluid);
                                            setTouched(true);
                                        }}
                                        onKeyDown={(e) => { e.preventDefault(); setViewedImage(null); }}
                                    >
                                        <StyledImg title={project.name} alt="Screenshot of project" fluid={size} frequency={project.frequency} objectFit="contain" width={dimensions.width} />
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </CarouselWrapper>
                : ''
            }
            {project.videoURL && (
                <iframe allowFullScreen={true} src={project.videoURL} title='project video' style={{width: '100%', height: '480px', marginBottom: '8px'}}/>
            )}
            {project.deployed && (
                <LinkDiv>
                    <a href={project.deployed}>
                        <LinkSpan><strong>Deployed Site</strong></LinkSpan>
                        <LinkSpan><StyledLinkIcon /></LinkSpan>
                    </a>
                </LinkDiv>
            )}
            {project.demo && (
                <LinkDiv>
                    <a href={project.demo}>
                        <LinkSpan><strong>Live Demo</strong></LinkSpan>
                        <LinkSpan><StyledLinkIcon /></LinkSpan>
                    </a>
                </LinkDiv>
            )}
            <div style={{display: "flex"}}>
                {project.frontend && (
                    <LinkDiv>
                        <a href={project.frontend}>
                            <LinkSpan style={{marginRight: "0.1rem"}}> <StyledGithub /> </LinkSpan>
                            <LinkSpan style={{marginRight: "0.5rem"}}><strong>Frontend repository</strong></LinkSpan>
                        </a>
                    </LinkDiv>
                )}
                {project.backend && (
                    <LinkDiv>
                        <a href={project.backend}>
                            <LinkSpan style={{marginRight: "0.1rem"}}> <StyledGithub /> </LinkSpan>
                            <LinkSpan style={{marginRight: "0.5rem"}}><strong>Backend repository</strong></LinkSpan>
                        </a>
                    </LinkDiv>
                )}
                {project.repo && (
                    <LinkDiv>
                        <a href={project.repo}>
                            <LinkSpan style={{marginRight: "0.1rem"}}> <StyledGithub /> </LinkSpan>
                            <LinkSpan style={{marginRight: "0.5rem"}}><strong>Repository</strong></LinkSpan>
                        </a>
                    </LinkDiv>
                )}
            </div>
            <div>
                <StyledSpan><strong>Tech Stack: </strong></StyledSpan>
                <H4>{project.tech}</H4>
            </div>
            <P><strong>Description: </strong>{project.description}</P>
            {project.bullets.length && (
                <div>
                    <StyledSpan><strong>Accomplishments: </strong></StyledSpan>
                    <ul>
                        {project.bullets.map((bullet, i) => (
                            <StyledLi key={i}>{bullet}</StyledLi>
                        ))}
                    </ul>
                </div>
            )}
        </ProjectContainer>
    );
};

const StyledGifWrapper = styled.div`
    background: rgba(76, 175, 80, 0);
    max-width: fit-content;
    max-height: 490px;
    border-radius: 10px;
    box-shadow: 0px 0px 40px 5px ${(props) => props.theme.tertiary};
    padding: 0.25rem;
    margin: 0 auto;
    img { margin: 0 auto; height: 480px; max-height: 480px; object-fit: contain; }
`;